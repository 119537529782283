import { Injectable } from "@angular/core";
import { AppService } from "./app.service";
import { DataService } from "./data.service";


@Injectable()
export class MinMaxValidationService {

  constructor(private dataService: DataService, private appService: AppService) {
  }
  globalVar: string = "test";
  MinMaxHardSoftValues: [];
  //runId: string = "";


  //setRunId(runId: string) {
  //  this.runId = runId;
  //}


  getValidationRangesForField(elementName: string){
    if (this.appService.MinMaxHardSoftValues != undefined) {
      //console.log(elementName);
      var validationField = this.appService.lodashFindBy(this.appService.MinMaxHardSoftValues, 'FieldName', elementName);
      //console.log(validationField);
      return validationField;
    }
  }
  
  setValidationList(runId: string) {
    //setTimeout(() => {
    //  this.dataService.get('Runs/GetMinMaxHardSoftLimitsForRun', 'RunId', runId).subscribe(
    //    (res: any) => {
    //     // console.log(res);
    //      this.MinMaxHardSoftValues = res;
    //    },
    //    err => {
    //      console.log(err);
    //    }
    //  );
    //}, 0);
  }

  getMinMaxHardSoftValues(): any {
    return this.appService.MinMaxHardSoftValues;
  }

  //setValidationList() {
  //  setTimeout(() => {
  //    this.dataService.getall('Settings/GetMinMaxHardSoftValidationList').subscribe(
  //      (res: any) => {
  //        console.log(res);
  //        localStorage.setItem('MinMaxHardSoftValues', JSON.stringify(res));
  //      },
  //      err => {
  //        console.log(err);
  //      }
  //    );
  //  }, 0);
  //}


  //getMinMaxHardSoftValues() {

  //  if (localStorage.getItem('MinMaxHardSoftValues') == null) {
  //    this.setValidationList();
  //  }

  //  var retrievedObject = localStorage.getItem('MinMaxHardSoftValues')

  //  return JSON.parse(retrievedObject);
  //}
}
