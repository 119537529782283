import { Directive, ElementRef, HostListener, SimpleChanges, } from '@angular/core';
import { Form, FormGroup, NgControl, NgModel } from '@angular/forms';
import { AppService } from '../services/app.service';
import { MinMaxValidationService } from '../services/minmax-validation.service';

@Directive({
  selector: '[validateMinMaxNumber]'
})
export class ValidateMinMaxNumberDirective {

  constructor(private element: ElementRef, private minMaxValuesService: MinMaxValidationService, private appService: AppService, private ngControl: NgControl) {

    //setTimeout(() => {//moved to ngOnInit 05-26-2022
      //console.log(ngControl);
         //this.validateInput(element.nativeElement.id, element.nativeElement.value);
    //}, 0)
    
  }

  ngOnInit() {
    setTimeout(() => {// this setTimeout is important in order for this directive to be executed after UnitValueConversionDirective directive that will need to convert values first if needed 05-31-2022
    this.validateInput(this.element.nativeElement.id, this.element.nativeElement.value);
    }, 0)
  }

  @HostListener('keyup', ['$event']) public onKeyup(event: KeyboardEvent, element: ElementRef): void {
    //console.log(element);
    this.validateInput(this.element.nativeElement.id, this.element.nativeElement.value);
  }
  //@HostListener('onNgModelChange', ['$event']) public onNgModelChange(event: KeyboardEvent, element: ElementRef): void {
  //  console.log('bingooo');
  //  this.validateInput(this.element.nativeElement.id, this.element.nativeElement.value);
  //}


  validateInput(elementName: string, elementValue: string) {
    //console.log(elementName);
    var validationField = this.appService.lodashFindBy(this.minMaxValuesService.getMinMaxHardSoftValues(), 'FieldName', elementName);
    var control = this.ngControl.control;// this.element.nativeElement;//this.formData.controls[elementName];
    
    control['softError'] = false;
    control['hardError'] = false;

    //if (elementName == 'PCO2') {
    //  console.log('bingo');
    //  console.log(validationField);
    //  console.log(elementValue);
    //}

    if (validationField != undefined && validationField != null) {
      if (elementValue != undefined && elementValue != null && elementValue != '') {
        if (Number(elementValue) > Number(validationField.HardMax) || Number(elementValue) < Number(validationField.HardMin)) {
          control.setErrors({ hardError: true });
          control['hardError'] = true;
         // console.log(control.errors);
          this.setHardSoftElementMessage(elementName, 1, validationField);

        } else {
          if (Number(elementValue) > Number(validationField.SoftMax) || Number(elementValue) < Number(validationField.SoftMin)) {
            control['softError'] = true;

            this.setHardSoftElementMessage(elementName, 2, validationField);
          } 
        }
       
      } else {
        control['softError'] = false;
        control['hardError'] = false;
        //control.setErrors({ hardError: null });
      }
    }
  }


  setHardSoftElementMessage(elementName: string, errorType: number, validationObject: any) {

    var stringContent = "";
    var hardSoftElement = elementName;

    if (errorType == 1) {//hard
      stringContent = "Value is outside range " + validationObject.HardMin + " and " + validationObject.HardMax;
      hardSoftElement = hardSoftElement + '-HardError';
    } else if (errorType == 2) {//soft
      stringContent = "Value is outside range " + validationObject.SoftMin + " and " + validationObject.SoftMax;
      hardSoftElement = hardSoftElement + '-SoftError';
    }

    setTimeout(() => {
      //console.log(hardSoftElement);
      if (document.getElementById(hardSoftElement))//added on 11-08-2023 to prevent console error of 'document.getElementById(hardSoftElement)' is undefined when directive called from component
      document.getElementById(hardSoftElement).innerHTML = stringContent;
    }, 0)
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.input) {
      console.log(changes.input);
      console.log('input changed');
    }
  }
}
