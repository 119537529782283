import { Component, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { ForTooltipComponent } from '../tooltip/for-tooltip.component';
import * as Highcharts from 'highcharts';
import { DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { PaginatorService } from '../../services/paginator.service';
import { LoadingService } from '../../services/loading.service';
import { BehaviorSubject, } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-allRuns',
  templateUrl: './allRuns.component.html',
})
export class AllRunsComponent {
  public allRuns: any;

  constructor(private router: Router, private dataService: DataService, private appService: AppService, private paginatorService: PaginatorService) {
    this.paginatorService.lowValue = 0;
    this.paginatorService.highValue = 5;
    this.dataService.getall('Home/GetAllRunsForCenter').subscribe(
      (res: any) => {
        //console.log(res.data);
        this.allRuns = res.data;
      },
      err => {
        console.log(err);
      }
    )
  }

  ngOnInit() {
    this.appService.Run = null;
    this.appService.Patient = null;
  }

  loadRun(runId: string) {
    //setting loading service to on, to show laoding for lazy loaded run module

    //Used when data service is not called
    //this.ls.isLoading$ = new BehaviorSubject<boolean>(true);
    //this.router.navigateByUrl('/run/runinfo/' + runId);
    /////////////////

    setTimeout(() => {

      this.dataService.get('Runs/GetRun', 'RunId', runId).subscribe(
        (res: any) => {

          this.appService.Patient = res.data.Patient;
          this.appService.Run = res.data.Run;
          this.appService.AllDateValidations = res.data.DateValidationList;
          this.appService.MinMaxHardSoftValues = res.data.MinMaxHardSoftValidationList;

          this.router.navigateByUrl('/run/runinfo/' + runId);
        },
        err => {
          console.log(err);
        }
      )
    }, 0);
  }

  loadPatient(patientId: string) {
    setTimeout(() => {

      this.dataService.get('Patients/GetPatient', 'PatientId', patientId).subscribe(
        (res: any) => {

          this.appService.Patient = res.data;

          //this.ls.isLoading = new BehaviorSubject<boolean>(true);
          this.router.navigateByUrl('/patient/patientinfo/' + patientId);
        },
        err => {
          console.log(err);
        }
      )
    }, 0);
  }
}
