import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../classes/user';
import { DataService } from '../../services/data.service'

import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AppService } from '../../services/app.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private toastr: ToastrService, private router: Router, private appService: AppService, private dateTimeFormatService: DateTimeFormatService) { }

  systemVersion: any;

  ngOnInit(): void {
    
    this.systemVersion = false;
    this.resetTokens();

    this.dataService.getall('Settings/GetSystemVersion').subscribe(
      (res: any) => {
        //console.log(res);
        this.systemVersion = res.data;
        if (!this.systemVersion.IsOffline) {//Commented for now, for testing
          this.dataService.get('Users/CheckForELSOLogin', null, null).subscribe(
            (res: any) => {
              //console.log(res.data);
              if (res.tokenValid == true) {
                localStorage.setItem('token', res.data);
                //should somehow login user with existing token
                this.dataService.get('Users/GetUserProfile', null, null).subscribe(
                  (res: any) => {
                    //need to set datetime format for user here
                    localStorage.setItem('unitSystem', res.UnitSystem);
                    localStorage.setItem('cultureSettings', res.DateTimeFormat);
                    localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
                    localStorage.setItem('centerName', res.Name);
                    localStorage.setItem('centerId', res.CenterId);
                    localStorage.setItem('userName', res.UserName);
                    localStorage.setItem('datePickerType', res.DatePickerType);
                    localStorage.setItem('goLiveAfterDate', res.Center.goLiveAfterDate);

                    this.appService.Center = res.Center;
                    if (this.appService.Center.GoLiveAfterDate) {
                      this.appService.CenterIsXML = true;
                    }

                    this.appService.setUserProfile();
                    /////
                    this.setDateTimeFormatService(res.DateTimeFormat);

                    this.dataService.setEnumsAndCenter();
                    //console.log(this.appService.UserProfile);

                    //this.router.navigateByUrl('/home')

                    if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true
                      || this.appService.isUserInRole('Multi Center User') == true || this.appService.isUserInRole('Multi Center XML Import') == true) {
                      //get centers for user, show dd list
                      this.dataService.getall('Users/GetCentersForUser').subscribe(
                        (res: any) => {
                          //this.centers = res.data;
                          //this.showCentersList = true;
                          this.appService.userCenters = res.data;

                          this.appService.centerQuery = this.appService.UserProfile.CenterName; //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', this.appService.UserProfile.CenterId).CenterId;

                          this.appService.filteredCenters = this.appService.userCenters;

                          //console.log(this.appService.centerQuery);
                          //console.log(this.appService.filteredCenters);
                          setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                            this.router.navigateByUrl('/home');
                          }, 100);
                        },
                        err => {
                          console.log(err);
                        }
                      );
                    } else {
                      setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                        this.router.navigateByUrl('/home');
                      }, 100);
                    }
                  },
                  err => {
                    console.log(err);
                  }
                );
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      },
      err => {
        console.log(err);
      }
    );

  
   // }
  }

  loginUserModel = new User('', '', 0, '');

  //public register() {
  //  this.dataService.register({ UserName: 'elso', Email: 'test@igdsolutions.com', Password: 'qweqweqwe123', FullName: 'elso'}).subscribe();
  //}

  public registerUser() {
    this.dataService.register(this.loginUserModel).subscribe(
      (res: any) => {
         //console.log(res);
        //this.toastr.success('Registration completed', 'Authentication success.');
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Error', 'Authentication failed.');
        else
          console.log(err);
      }
    );
  }

  showTfaOptions = false;
  tfaCodeSent = false;
  public submitForm() {
    if (this.showTfaOptions == true) {
      //send tfa code
      this.dataService.create('Users/RequestTFAcode', this.loginUserModel).subscribe(
        (res: any) => {
          this.showTfaOptions = false;
          this.tfaCodeSent = true;

          //this.toastr.success(res.message);
        },
        err => {
          console.log(err);
          this.toastr.error(err.error.message);
        }
      );
    } else if (this.tfaCodeSent == true) {
      //validate code and login user
      this.dataService.create('Users/VerifyTFAcode', this.loginUserModel).subscribe(
        (res: any) => {
          if (res.codeConfirmed == true) {
            this.showTfaOptions = false;
            this.tfaCodeSent = false;

            this.login();
          } else {
            this.toastr.error(res.message);
          }
        },
        err => {
          this.toastr.error(err.error.message);
        }
      );
    } else {
      //login user
      this.login();
    }
  }

  public resetForm() {
    this.showTfaOptions = false;
    this.tfaCodeSent = false;

    this.loginUserModel = new User('', '', 0, '');
  }

  showResetPassword = false;
  resetPasswordLink = '';
  fullName = '';
  public login() {
   // console.log(this.loginUserModel);
    this.dataService.login(this.loginUserModel).subscribe(
      (res: any) => {
       // console.log(res);

        if (res.resetLink) {
          this.showResetPassword = true;
          this.resetPasswordLink = res.resetLink;
          this.fullName = res.fullName;

          return;
        }

        if (res.tfaNotSelected == true) {
          //show tfa option selection
          this.showTfaOptions = true;
          return
        }
        if (res.tfaCodeSent == true) {
          //show tfa code input field
          this.tfaCodeSent = true;
          //console.log(this.loginUserModel);
          return;
        }

        //before token set, should check if some TFA message or error message is returned
        localStorage.setItem('token', res.data);
        this.toastr.success('Welcome to ELSO Registry', 'Authentication success.');

        //var tempToken = res.token;
        //console.log(tempToken);
        ////tempToken = tempToken.replace('e', 'w');
        //console.log(tempToken);
        //this.dataService.update('Users/CheckIfELSOTokenisValid', { 'Token': tempToken, DnnIpAddress: 'dnnIPaddress'}).subscribe(
        //  (res: any) => {
        //    console.log(res);
        //  },
        //  err => {
        //    console.log(err);
        //  }
        //);
        var tokenHeader = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('token') });
        //var tokenHeader = new Headers({ 'Authorization': 'Bearer ' + localStorage.getItem('token') });
        this.dataService.get('Users/GetUserProfile', null, null).subscribe(
          (res: any) => {
            //need to set datetime format for user here
            localStorage.setItem('unitSystem', res.UnitSystem);
            localStorage.setItem('cultureSettings', res.DateTimeFormat);
            localStorage.setItem('cultureOptionSelected', res.CultureOptionSelected);
            localStorage.setItem('centerName', res.Name);
            localStorage.setItem('centerId', res.CenterId);
            localStorage.setItem('userName', res.UserName);
            localStorage.setItem('datePickerType', res.DatePickerType);
            localStorage.setItem('goLiveAfterDate', res.Center.goLiveAfterDate);

            this.appService.Center = res.Center;
            if (this.appService.Center.GoLiveAfterDate) {
              this.appService.CenterIsXML = true;
            }

            this.appService.setUserProfile();
            /////
            this.setDateTimeFormatService(res.DateTimeFormat);

            this.dataService.setEnumsAndCenter();
            //console.log(this.appService.UserProfile);

              //this.router.navigateByUrl('/home')
            if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true
              || this.appService.isUserInRole('Multi Center User') == true || this.appService.isUserInRole('Multi Center XML Import') == true) {
                //get centers for user, show dd list
                this.dataService.getall('Users/GetCentersForUser').subscribe(
                  (res: any) => {
                    //this.centers = res.data;
                    //this.showCentersList = true;
                    this.appService.userCenters = res.data;

                    this.appService.centerQuery = this.appService.UserProfile.CenterName; //this.appService.lodashFindBy(this.appService.userCenters, 'CenterId', this.appService.UserProfile.CenterId).CenterId;

                    this.appService.filteredCenters = this.appService.userCenters;

                    //console.log(this.appService.centerQuery);
                    //console.log(this.appService.filteredCenters);

                    setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                      this.router.navigateByUrl('/home');
                    }, 100);
                  },
                  err => {
                    console.log(err);
                  }
                );
              } else {
                setTimeout(() => {///allow timeout for local storage to be set properly because of date format pipe display
                  this.router.navigateByUrl('/home');
                }, 100);
              }           
          },
          err => {
            console.log(err);
          }
        );
        //this.router.navigateByUrl('/home')
      },
      err => {
        console.log(err);
        if (err.status == 400)
          this.toastr.error('Authentication failed.', err.error.message);
        else
          this.toastr.error('Unexpected error.');
      }
    )
    //return this.http.post()
  }

  setDateTimeFormatService(settings: any) {
    var cultureSettings = settings.split('|');
    var l = cultureSettings[0];
    var f = cultureSettings[1];
    var t = (cultureSettings[2] === 'true');
    this.dateTimeFormatService.locale = l;
    this.dateTimeFormatService.format = f;
    this.dateTimeFormatService.twelveHourFormat = t;
  }

  resetTokens() {
    localStorage.removeItem('cultureSettings');
    localStorage.removeItem('unitSystem');
    localStorage.removeItem('cultureOptionSelected');
    localStorage.removeItem('centerName');
    localStorage.removeItem('centerId');
    localStorage.removeItem('token');

    this.appService.CenterIsXML = false;
  }

  redirectToKeyCloak() {

  }
  //Integrated equipment test
  //devicesNew = [//devices array that starts with integrated device
  //  { DeviceName: 'Integrated 0', DeviceType: 3, DeviceId: 0, ReplacedByDevicesArray: [1, 3], ReplacedDevicesArray: [] }, //integrated device
  //  { DeviceName: 'Membrane Lung 1', DeviceType: 1, DeviceId: 1, ReplacedByDevicesArray: [2], ReplacedDevicesArray: [0] }, //Membrane Lung
  //  { DeviceName: 'Membrane Lung 2', DeviceType: 1, DeviceId: 2, ReplacedByDevicesArray: [4], ReplacedDevicesArray: [1] },//Membrane Lung
  //  { DeviceName: 'Pump 1', DeviceType: 2, DeviceId: 3, ReplacedByDevicesArray: [4], ReplacedDevicesArray: [0] }, //Pump
  //  { DeviceName: 'Integrated 1', DeviceType: 3, DeviceId: 4, ReplacedByDevicesArray: [5], ReplacedDevicesArray: [2, 3] }, //integrated device
  //  { DeviceName: 'Integrated 2', DeviceType: 3, DeviceId: 5, ReplacedByDevicesArray: [6], ReplacedDevicesArray: [4] }, //integrated device
  //  { DeviceName: 'Integrated 3', DeviceType: 3, DeviceId: 6, ReplacedByDevicesArray: [7, 8], ReplacedDevicesArray: [5] }, //integrated device
  //  { DeviceName: 'Membrane Lung 3', DeviceType: 1, DeviceId: 7, ReplacedByDevicesArray: [9], ReplacedDevicesArray: [6] }, //Membrane Lung
  //  { DeviceName: 'Pump 2', DeviceType: 2, DeviceId: 8, ReplacedByDevicesArray: [9], ReplacedDevicesArray: [6] }, //Pump
  //  { DeviceName: 'Integrated 4', DeviceType: 3, DeviceId: 9, ReplacedByDevicesArray: [], ReplacedDevicesArray: [7, 8] }, //integrated device
  //];
  //devicesNew = [//devices array that starts with standard devices in their section
  //  { DeviceName: 'Membrane Lung 1', DeviceType: 1, DeviceId: 1, ReplacedByDevicesArray: [2], ReplacedDevicesArray: [] }, //Membrane Lung
  //  { DeviceName: 'Membrane Lung 2', DeviceType: 1, DeviceId: 2, ReplacedByDevicesArray: [4], ReplacedDevicesArray: [1] },//Membrane Lung
  //  { DeviceName: 'Pump 1', DeviceType: 2, DeviceId: 3, ReplacedByDevicesArray: [4], ReplacedDevicesArray: [] }, //Pump
  //  { DeviceName: 'Integrated 1', DeviceType: 3, DeviceId: 4, ReplacedByDevicesArray: [5], ReplacedDevicesArray: [2, 3] }, //integrated device
  //  { DeviceName: 'Integrated 2', DeviceType: 3, DeviceId: 5, ReplacedByDevicesArray: [6], ReplacedDevicesArray: [4] }, //integrated device
  //  { DeviceName: 'Integrated 3', DeviceType: 3, DeviceId: 6, ReplacedByDevicesArray: [7, 8], ReplacedDevicesArray: [5] }, //integrated device
  //  { DeviceName: 'Membrane Lung 3', DeviceType: 1, DeviceId: 7, ReplacedByDevicesArray: [9], ReplacedDevicesArray: [6] }, //Membrane Lung
  //  { DeviceName: 'Pump 2', DeviceType: 2, DeviceId: 8, ReplacedByDevicesArray: [9], ReplacedDevicesArray: [6] }, //Pump
  //  { DeviceName: 'Integrated 4', DeviceType: 3, DeviceId: 9, ReplacedByDevicesArray: [], ReplacedDevicesArray: [7, 8] }, //integrated device
  //];
  devicesNew = [//devices array that starts with standard devices in their section
    { DeviceName: 'Membrane Lung 1', DeviceType: 1, DeviceId: 1, ReplacedByDevicesArray: [2], ReplacedDevicesArray: [] }, //Membrane Lung
    { DeviceName: 'Membrane Lung 2', DeviceType: 1, DeviceId: 2, ReplacedByDevicesArray: [4], ReplacedDevicesArray: [1] },//Membrane Lung
    { DeviceName: 'Pump 1', DeviceType: 2, DeviceId: 3, ReplacedByDevicesArray: [4], ReplacedDevicesArray: [] }, //Pump
    { DeviceName: 'Integrated 1', DeviceType: 3, DeviceId: 4, ReplacedByDevicesArray: [], ReplacedDevicesArray: [2, 3] }, //integrated device
  ];
  //should create function that always accepts array of replacements. and change standard devices property ReplacedDId to array
  isReplacedByDeviceType2(device, deviceType) {
    if (device.ReplacedByDevicesArray.length > 0) {
      for (var i = 0; i <= device.ReplacedByDevicesArray.length - 1; i++) {
        var replacementDevice = this.appService.lodashFindBy(this.devicesNew, 'DeviceId', device.ReplacedByDevicesArray[i]);

        if (replacementDevice.DeviceType == deviceType) {
          return true
        }
      }
    }

    return false;
  }
  getDevicesReplacedByIntegratedDevice(integratedDId) {
    //console.log(this.appService.lodashFindBy(this.devicesNew, 'DeviceId', integratedDId).ReplacedDevicesArray);
    return this.appService.lodashFindBy(this.devicesNew, 'DeviceId', integratedDId).ReplacedDevicesArray;
  }
  getIntegratedReplacementList(deviceId): any {
    var listToReturn = [];

    listToReturn = this.getIntegratedReplacementOrder(deviceId, []);

    //console.log(listToReturn);

    listToReturn = [this.appService.lodashFindBy(this.devicesNew, 'DeviceId', deviceId)].concat(listToReturn) // add first device in first place in integrated devices array

    return listToReturn;
  }
  getIntegratedReplacementOrder(deviceId, deviceArray): any {
    var device = this.appService.lodashFindBy(this.devicesNew, 'DeviceId', deviceId);
    //console.log(device.DeviceId);
    if (device) {
      if (device.DeviceType == 3) {
        //integrated Device Exists
        //Check if more integrated devices replaced this current integrated device
        if (device.ReplacedByDevicesArray.length == 1) {//if one device replaced current integrated device, that means its integrated devices
          //if (this.appService.lodashFindBy(this.devicesNew, 'DeviceId', device.ReplacedByDevicesArray[0]).DeviceType == 3) {//this line isnt needed because if there is only one replacement on integrated device, replacement device must be integrated type
            var replacementDevice = this.appService.lodashFindBy(this.devicesNew, 'DeviceId', device.ReplacedByDevicesArray[0]);

            deviceArray.push(replacementDevice);

            this.getIntegratedReplacementOrder(replacementDevice.DeviceId, deviceArray)
          //}
        }
      }
    }

    return deviceArray;
  }

  getDeviceColor(deviceId) {
    var device = this.appService.lodashFindBy(this.devicesNew, 'DeviceId', deviceId);
    var color = '';
    switch (device.DeviceType) {
      case 1:
        color = 'blue';
        break;
      case 2:
        color = 'purple';
        break;
    }

    return color;
  }

  startingDeviceId = 10;

  replaceDevice(device, deviceTypeReplacement) {
    //console.log(device);
    switch (device.DeviceType) {
      case 1:
        if (deviceTypeReplacement != 3) {
          //standard
          this.devicesNew.push({ DeviceName: 'Membrane Lung ' + this.startingDeviceId, DeviceType: deviceTypeReplacement, DeviceId: this.startingDeviceId, ReplacedByDevicesArray: [], ReplacedDevicesArray: [device.DeviceId] });
          device.ReplacedByDevicesArray = [this.startingDeviceId];
          this.startingDeviceId = this.startingDeviceId + 1;
        } else {
          //integrated
          //should also replace other standard devices that are close to this device time with this new integrated device
          var otherDeviceTypes = this.appService.lodashFilterBy(this.devicesNew, 'DeviceType', 2); //devices that are not membrane lungs, in this example membrane lungs, but here we should take source from new added integrated device (tr + bp for example, this should be automatic)
          var otherDeviceToUpdateWithReplacement = otherDeviceTypes[otherDeviceTypes.length - 1];

          this.devicesNew.push({ DeviceName: 'Integrated ' + this.startingDeviceId, DeviceType: deviceTypeReplacement, DeviceId: this.startingDeviceId, ReplacedByDevicesArray: [], ReplacedDevicesArray: [device.DeviceId, otherDeviceToUpdateWithReplacement.DeviceId] });

          otherDeviceToUpdateWithReplacement.ReplacedByDevicesArray.push(this.startingDeviceId);//replacing bp device with integrated
          device.ReplacedByDevicesArray = [this.startingDeviceId];//replacing standard device with integrated

          this.startingDeviceId = this.startingDeviceId + 1;
        }
        break;
      case 2:
        if (deviceTypeReplacement != 3) {
          //standard
          this.devicesNew.push({ DeviceName: 'Pump ' + this.startingDeviceId, DeviceType: deviceTypeReplacement, DeviceId: this.startingDeviceId, ReplacedByDevicesArray: [], ReplacedDevicesArray: [device.DeviceId] });
          device.ReplacedByDevicesArray = [this.startingDeviceId];
          this.startingDeviceId = this.startingDeviceId + 1;
        } else {
          //integrated

          //should also replace other standard devices that are close to this device time with this new integrated device
          var otherDeviceTypes = this.appService.lodashFilterBy(this.devicesNew, 'DeviceType', 1); //devices that are not pumps, in this example pumps, but here we should take source from new added integrated device (tr + bp for example, this should be automatic)
          var otherDeviceToUpdateWithReplacement = otherDeviceTypes[otherDeviceTypes.length - 1];
          
          this.devicesNew.push({ DeviceName: 'Integrated ' + this.startingDeviceId, DeviceType: deviceTypeReplacement, DeviceId: this.startingDeviceId, ReplacedByDevicesArray: [], ReplacedDevicesArray: [device.DeviceId, otherDeviceToUpdateWithReplacement.DeviceId] });

          otherDeviceToUpdateWithReplacement.ReplacedByDevicesArray.push(this.startingDeviceId);//replacing ml device with integrated
          device.ReplacedByDevicesArray = [this.startingDeviceId];//replacing standard device with integrated


          this.startingDeviceId = this.startingDeviceId + 1;
        }
       

        break;
      case 3:
        if (deviceTypeReplacement != 3) {
          //standard
          //for (var i = 0; i <= device.ReplacedDevicesArray.length - 1; i++) {
          //  var replacedDevice = this.appService.lodashFindBy(this.devicesNew, 'DeviceId', device.ReplacedDevicesArray[i]);
          //  this.devicesNew.push(
          //    {
          //      DeviceName: this.getDeviceName(replacedDevice.DeviceType) + ' ' + this.startingDeviceId,
          //      DeviceType: replacedDevice.DeviceType,
          //      DeviceId: this.startingDeviceId,
          //      ReplacedByDevicesArray: [],
          //      ReplacedDevicesArray: [device.DeviceId]
          //    });

          //  device.ReplacedByDevicesArray.push(this.startingDeviceId);
          //  this.startingDeviceId = this.startingDeviceId + 1;
          //}
            this.devicesNew.push(
              {
                DeviceName: 'Membrane Lung ' + this.startingDeviceId,
                DeviceType: 1,
                DeviceId: this.startingDeviceId,
                ReplacedByDevicesArray: [],
                ReplacedDevicesArray: [device.DeviceId]
              });

            device.ReplacedByDevicesArray.push(this.startingDeviceId);
          this.startingDeviceId = this.startingDeviceId + 1;

          this.devicesNew.push(
            {
              DeviceName: 'Pump ' + this.startingDeviceId,
              DeviceType: 2,
              DeviceId: this.startingDeviceId,
              ReplacedByDevicesArray: [],
              ReplacedDevicesArray: [device.DeviceId]
            });

          device.ReplacedByDevicesArray.push(this.startingDeviceId);
          this.startingDeviceId = this.startingDeviceId + 1;

        } else {
          //integrated
          this.devicesNew.push(
            {
              DeviceName: 'Integrated ' + this.startingDeviceId,
              DeviceType: deviceTypeReplacement,
              DeviceId: this.startingDeviceId,
              ReplacedByDevicesArray: [],
              ReplacedDevicesArray: [device.DeviceId]
            });

          device.ReplacedByDevicesArray.push(this.startingDeviceId);
          this.startingDeviceId = this.startingDeviceId + 1;
        }
        

        break;
    }

    //console.log(this.devicesNew);
  }


  getDeviceName(deviceType) {
    var deviceName = '';
    switch (deviceType) {
      case 1:
        deviceName = 'Membrane Lung';
        break;
      case 2:
        deviceName = 'Pump';
        break;
      case 3:
        deviceName = 'Integrated';
        break;
    }

    return deviceName;
  }




 
  //devices = [
  //  { DeviceName: 'Integrated 0', DeviceType: 3, DeviceId: 0, ReplacedByDevices: [1, 3] }, //integrated device
  //  { DeviceName: 'Membrane Lung 1', DeviceType: 1, DeviceId: 1, ReplacedByDId: 2, ReplacedDId: 0, }, //Membrane Lung
  //  { DeviceName: 'Membrane Lung 2', DeviceType: 1, DeviceId: 2, ReplacedDId: 1, ReplacedByDId: 4 },//Membrane Lung
  //  { DeviceName: 'Pump 1', DeviceType: 2, DeviceId: 3, ReplacedByDId: 4, ReplacedDId: 0, }, //Pump
  //  { DeviceName: 'Integrated 1', DeviceType: 3, DeviceId: 4, ReplacedDId: [2, 3], ReplacedByDId: 5 }, //integrated device
  //  { DeviceName: 'Integrated 2', DeviceType: 3, DeviceId: 5, ReplacedDId: 4, ReplacedByDId: 6 }, //integrated device
  //  { DeviceName: 'Integrated 3', DeviceType: 3, DeviceId: 6, ReplacedDId: 5, ReplacedByDId: 7, ReplacedByDevices: [7, 8] }, //integrated device
  //  { DeviceName: 'Membrane Lung 3', DeviceType: 1, DeviceId: 7, ReplacedDId: 6, ReplacedByDId: 9 }, //Membrane Lung
  //  { DeviceName: 'Pump 2', DeviceType: 2, DeviceId: 8, ReplacedDId: 6, ReplacedByDId: 9 }, //Pump
  //  { DeviceName: 'Integrated 4', DeviceType: 3, DeviceId: 9, ReplacedDId: [7, 8] }, //integrated device
  //];

  //isReplacedByIntegratedOrSame(deviceId): boolean {

  //  if (deviceId != null) {
  //    if (this.appService.lodashFindBy(this.devices, 'DeviceId', deviceId).DeviceType == 3) {
  //      return true;
  //    }
  //  } else {
  //    return null;
  //  }

  //  return false;
  //}
  //isReplacedByDeviceType(deviceId, deviceType): boolean {
  //  if (deviceId != null) {
  //    if (this.appService.lodashFindBy(this.devices, 'DeviceId', deviceId).DeviceType == deviceType) {
  //      return true;
  //    }
  //  }

  //  return false;
  //}
  //isReplacedByMultipleDeviceTypes(deviceId, deviceTypes): boolean {

  //  if (deviceId != null) {
  //    if (this.appService.lodashFindBy(this.devices, 'DeviceId', deviceId).DeviceType == deviceTypes) {
  //      return true;
  //    }
  //  }

  //  return false;
  //}

  //getReplacedByIntegrated(device) {
  //  var integratedD = this.appService.lodashFindBy(this.devices, 'DeviceId', device.ReplacedByDId);


  //  var combinedDevicesWithoutCurrentReplaced = integratedD.ReplacedDId.filter(dId => dId != device.DeviceId);
  // // console.log(combinedDevicesWithoutCurrentReplaced);
  //  var deviceNames = '';
  //  for (var i = 0; i <= combinedDevicesWithoutCurrentReplaced.length - 1; i++) {
  //   // console.log(combinedDevicesWithoutCurrentReplaced[i]);
  //    //console.log(this.appService.lodashFindBy(this.devices, 'DeviceId', combinedDevicesWithoutCurrentReplaced[i]));
  //    deviceNames = deviceNames + ' ' + this.appService.lodashFindBy(this.devices, 'DeviceId', combinedDevicesWithoutCurrentReplaced[i]).DeviceName;
  //  }

  //  //console.log(deviceNames);
  //  return deviceNames;      
  //}





  
}
