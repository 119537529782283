import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'report-support-mode-from-year',
  templateUrl: './reportSupportModeFromYear.component.html',
})

export class ReportSupportModeFromYear {
  @Input() public reportData: any;



  constructor() {
    //console.log('myCustomComponent');

  }


  OnInit() {
    console.log(this.reportData);
  }
}
