import { Component, Input } from '@angular/core';
import { AppService } from '../../services/app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { DateTimeFormatService } from '../../services/datetime-format.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';

@Component({
  templateUrl: './runDataDump.component.html',
})

export class RunDataDumpComponent {

  constructor(private ls: LoadingService, private dataService: DataService, private appService: AppService, private _dateTimeService: DateTimeFormatService, private fb: FormBuilder, private router: Router
    , private dateTimeService: DateTimeFormatService) { }

  form!: FormGroup;
  centers = [];
  showCentersList: boolean = false;


  save() {
    var center = null;
    this.dataService.get('Users/GetCenterForCenterId', 'CenterId', this.form.controls['CenterId'].value).subscribe(
      (res: any) => {
        center = res.Name + '(' + res.CenterNo + ')';
      },
      err => {
        console.log(err);
      }
    );

    this.dataService.getRunDataDump('DataDump/RunDataDump', this.form.value).subscribe(
      (res: File) => {
        var formatedDate = moment().utc(true).locale(this.dateTimeService.locale).format(this.dateTimeService.format);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'DataDump-Center(' + center + ')-' + formatedDate +'.xlsx';// 'export.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
   
   
  }

  ngOnInit() {
    this.appService.Run = null;
    this.appService.Patient = null;

    //console.log(this.appService.UserProfile);
    this.form = this.fb.group({
      CenterId: new FormControl(this.appService.UserProfile.CenterId, Validators.required),
      StartTime: new FormControl(null),
      EndTime: new FormControl(null)
    });

    if (this.appService.isUserInRole('Host User') == true || this.appService.isUserInRole('Host User - Registry Only') == true || this.appService.isUserInRole('Multi Center User') == true) {
      //get centers for user, show dd list
      this.dataService.getall('Users/GetCentersForUser').subscribe(
        (res: any) => {
          this.centers = res.data;
          this.showCentersList = true;
        },
        err => {
          console.log(err);
        }
      );
    }
    
  }

  createAscendDataDump() {
    this.dataService.getRunDataDump('DataDump/AscendDataDump', null).subscribe(
      (res: File) => {
        var formatedDate = moment().locale(this.dateTimeService.locale).format(this.dateTimeService.format);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = 'AscendList-' + formatedDate + '.xlsx';
        // start download
        a.click();
      },
      err => {
        console.log(err);
      }
    );
  }

  validateStartTimeVsEndTime = (startTime?: FormControl, endTime?: FormControl): void => {
    //console.log(startTime);
    //console.log(endTime);
    if (startTime.value != null && endTime.value != null) {
      var startTimeCompare = moment(startTime.value);

      if (endTime) {
        var endTimeCompare = moment(endTime.value);
        if (startTimeCompare > endTimeCompare) {
          startTime.setErrors({ startTimevsEndTime: true });
        }
        else if (startTime.hasError('startTimevsEndTime')) {
          delete startTime.errors['startTimevsEndTime'];
          startTime.updateValueAndValidity();
        }
      }
    }

    //this.form.controls['StartTime'].updateValueAndValidity();
    //this.form.controls['EndTime'].updateValueAndValidity();
    //Required to properly revalidate form errors
    //setTimeout(() => {
    //  this.refreshControlErrors();
    //}, 100);
  }
}
