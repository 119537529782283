import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'report-overall-outcome',
  templateUrl: './reportOverallOutcome.component.html',
})

export class ReportOverallOutcomeComponent {
  @Input() public reportData: any;



  constructor() {
    //console.log('myCustomComponent');

  }

  public softMin: string;
  public softMax: string;
  public hardMin: string;
  public hardMax: string;

  OnInit() {
    console.log(this.reportData);
  }
}
