import { Directive, ElementRef, HostListener, Input, } from '@angular/core';
import { Form, FormGroup, NgControl, NgModel } from '@angular/forms';
import { AppService } from '../services/app.service';
import { MinMaxValidationService } from '../services/minmax-validation.service';

@Directive({
  selector: '[validateNumberPrecision]'
})
export class ValidateNumberPrecisionDirective {

  @Input() public validateNumberPrecision: string;


  constructor(private element: ElementRef, private appService: AppService, private ngControl: NgControl) {

  }

  ngOnInit() {
    //console.log(this.ngControl.control.value);
    this.validateInput(this.element.nativeElement.value);
  }

  @HostListener('keyup', ['$event']) public onKeyup(event: KeyboardEvent, element: ElementRef): void {
    //console.log(element);
    this.validateInput(this.element.nativeElement.value);
  }

  validateInput(elementValue: string) {
    var control = this.ngControl.control;
    control['maxPrecision'] = false;

    var decimalPlaces = this.countDecimalPlaces(elementValue);
    if (decimalPlaces > parseInt(this.validateNumberPrecision)) {
      control['maxPrecision'] = true;
      control.setErrors({ maxPrecision: true });
    } else {
      control['maxPrecision'] = false;
    }
    //console.log(decimalPlaces);
    
    //control['hardError'] = false;


  }

  countDecimalPlaces(value: any) {
    //console.log(value);
    var decimalPos = String(value).indexOf('.');
    if (decimalPos === -1) {
      return 0;
    } else {
      return String(value).length - decimalPos - 1;
    }
  }


  
}
