import { Directive, ElementRef, HostListener, } from '@angular/core';
import { Form, FormGroup, NgControl, NgModel } from '@angular/forms';
import { AppService } from '../services/app.service';
import { MinMaxValidationService } from '../services/minmax-validation.service';

@Directive({
  selector: '[validateDate]'
})
export class ValidateDateDirective {

  constructor(private element: ElementRef, private minMaxValuesService: MinMaxValidationService, private appService: AppService, private ngControl: NgControl) {

    setTimeout(() => {
      //console.log('date directive');
      //console.log(element.nativeElement.id);
      //console.log(this.element.nativeElement.value);
    }, 0)

  }

  @HostListener('keyup', ['$event']) public onKeyup(event: KeyboardEvent, element: ElementRef): void {
   // console.log(element);
   // this.validateInput(this.element.nativeElement.id, this.element.nativeElement.value);
  }

  validateInput(elementName: string, elementValue: string) {

  }



}
