import { Component, Directive, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MinMaxValidationService } from '../../services/minmax-validation.service';

@Directive({
  selector: '.tooltip-container'
})
export class TooltipContainerDirective {
}

@Component({
  //template: `
  //  <div class="tooltip-container" [ngStyle]="{top: top}">
  //    <ng-content></ng-content>
  //  </div>
  //`,
  templateUrl: './tooltip.component.html',
  //styles: [
  //  `
  //    .tooltip-container {
  //      background-color: black;
  //      color: #fff;
  //      display: inline-block;
  //      padding: 0.5em;
  //      position: absolute;
  //    }
  //  `
  //]
})
export class MinMaxTooltipComponent implements OnInit {
  top: string;
  left: string;
  @ViewChild(TooltipContainerDirective, { read: ElementRef })  public tooltipContainer;

  constructor(@Inject('tooltipConfig') private config, private minMaxValuesService: MinMaxValidationService) {
    //console.log(config.host.attributes['tooltip'].value);
  }

  softMin: string;
  softMax: string;
  hardMin: string;
  hardMax: string;
  description: string;

  ngOnInit() {
    Promise.resolve().then(() => {
      var validationField = this.minMaxValuesService.getValidationRangesForField(this.config.host.attributes['tooltip'].value);
      if (validationField) {
        this.softMin = validationField.SoftMin;
        this.softMax = validationField.SoftMax;
        this.hardMin = validationField.HardMin;
        this.hardMax = validationField.HardMax;

        if (this.config.host.attributes['customTooltipDesc']) {
          this.description = this.config.host.attributes['customTooltipDesc'].value;
        } else {
          this.description = validationField.LongName;
        }
        //console.log(validationField);

        //const { top } = this.config.host.getBoundingClientRect();
        //console.log(this.tooltipContainer.nativeElement.getBoundingClientRect());
        //console.log(this.tooltipContainer.nativeElement);
        const { height } = this.tooltipContainer.nativeElement.getBoundingClientRect();

        var element = this.tooltipContainer.nativeElement;

        var result = this.getOffset(element);
        //console.log(result);

        this.top = `${result.top - (height * 4.4)}px`;//added on 01-19-2024
        this.left = `${result.left - 60}px`;//added on 01-19-2024
        //this.top = `${result.top - (height * 2.2)}px`;//commented on 01-19-2024
      //this.top = `${top - (height * 2.2)}px`;
      }
      
    });
   
  }


  getOffset(el) {
      var sideNavMainElement = document.getElementById('sideNavMain');
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + sideNavMainElement.scrollLeft,
        top: rect.top + sideNavMainElement.scrollTop
    };
  }

}
