import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostListener, Injector, Input, ReflectiveInjector, Renderer2, TemplateRef, Type, ViewContainerRef, ViewRef } from '@angular/core';
import { MinMaxTooltipComponent } from '../components/tooltip/tooltip.component';
import { MinMaxValidationService } from '../services/minmax-validation.service';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  // We can pass string, template or component
  @Input('tooltip') content: string | TemplateRef<any> | Type<any>;
  private componentRef: ComponentRef<MinMaxTooltipComponent>;

  constructor( private minMaxValuesService: MinMaxValidationService, private element: ElementRef,
    private renderer: Renderer2,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private vcr: ViewContainerRef) {
  }

//  @HostListener('mouseenter')
//  mouseenter() {
//    if (this.componentRef) return;
//    const factory = this.resolver.resolveComponentFactory(TooltipComponent);
//    const injector = ReflectiveInjector.resolveAndCreate([
//      {
//        provide: 'tooltipConfig',
//        useValue: {
//          host: this.element.nativeElement
//        }
//      }
//    ]);
////    this.componentRef = this.vcr.createComponent(factory, 0, injector, this.generateNgContent());
//    this.componentRef = this.vcr.createComponent(factory, 0, injector);
//  }

  @HostListener('mouseover')//mouseover works better then mouse enter because its more precise and not causing bug to not display popup
  mouseover() {
    if (this.componentRef) return;
    const factory = this.resolver.resolveComponentFactory(MinMaxTooltipComponent);
    const injector = ReflectiveInjector.resolveAndCreate([
      {
        provide: 'tooltipConfig',
        useValue: {
          host: this.element.nativeElement
        }
      }
    ]);
    this.componentRef = this.vcr.createComponent(factory, 0, injector);
  }

  generateNgContent() {
    if (typeof this.content === 'string') {
      var validationField = this.minMaxValuesService.getValidationRangesForField(this.content);
      //console.log(validationField);
      const element = this.renderer.createText(this.content);


      //var container = this.renderer.createElement('div');

      //var softMin = this.renderer.createElement('span');
      //var text = this.renderer.createText('Soft Min: ');
      //this.renderer.appendChild(softMin, text);

      //var softMax = this.renderer.createElement('span');
      //text = this.renderer.createText('Soft Max: ');
      //this.renderer.appendChild(softMin, text);

      //var hardMin = this.renderer.createElement('span');
      //text = this.renderer.createText('Hard Min: ');
      //this.renderer.appendChild(softMin, text);

      //var hardMax = this.renderer.createElement('span');
      //text = this.renderer.createText('Hard Max: ');
      //this.renderer.appendChild(softMin, text);

      //this.renderer.setStyle(softMin, 'color', 'green');
      //this.renderer.setStyle(softMax, 'color', 'green');

      //this.renderer.setStyle(hardMin, 'color', 'red');
      //this.renderer.setStyle(hardMax, 'color', 'red');

      //text = this.renderer.createText(validationField.SoftMin);
      //this.renderer.appendChild(softMin, text);

      //text = this.renderer.createText(validationField.SoftMax);
      //this.renderer.appendChild(softMax, text);

      //text = this.renderer.createText(validationField.HardMin);
      //this.renderer.appendChild(hardMin, text);

      //text = this.renderer.createText(validationField.HardMax);
      //this.renderer.appendChild(hardMax, text);

      //this.renderer.appendChild(container, softMin);
      //this.renderer.appendChild(container, softMax);
      //this.renderer.appendChild(container, hardMin);
      //this.renderer.appendChild(container, hardMax);

      //var text = this.renderer.createText(validationField.SoftMax);


      //this.renderer.appendChild(softMin, text);
      return [[element]];
    }

    if (this.content instanceof TemplateRef) {
      const viewRef = this.content.createEmbeddedView({});
      return [viewRef.rootNodes];
    }

    // Else it's a component
    const factory = this.resolver.resolveComponentFactory(this.content);
    const viewRef = factory.create(this.injector);
    return [[viewRef.location.nativeElement]];
  }

  @HostListener('mouseout')
  mouseout() {
    this.destroy();
  }

  destroy() {
    //console.log(this.componentRef);
    this.componentRef && this.componentRef.destroy();
    this.componentRef = null;
  }

  ngOnDestroy() {
    this.destroy();
  }

}
