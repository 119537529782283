import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'elsoGridFilter',
  pure: false
})
export class ELSOFilterPipe implements PipeTransform {
  constructor(private appService: AppService) { }

  transform(items: any[], filter: string, sortByProperty?: string, sortByOrder?: string): any {
    //console.log(sortByProperty);
    if (!items) {
      return items;
    }
    var sortedFilteredArray = items;
    var sortByOrderDefault = 'asc';
    if (sortByOrder) {
      sortByOrderDefault = sortByOrder
    }

    if (sortByProperty) {
      sortedFilteredArray = this.appService.lodashOrderBy(sortedFilteredArray, [sortByProperty], [sortByOrderDefault]);
    }

    if (filter) {
      if (filter.trim() != "") {
        sortedFilteredArray = this.filterByValue(sortedFilteredArray, filter);
      }
    }
    return sortedFilteredArray;
  }

  filterByValue(array, string) {
    return array.filter(o => {
      return Object.keys(o).some(k => {
        if (o[k] != null)
          return o[k].toString().toLowerCase().includes(string.toLowerCase())
      })
    });
  }
}
