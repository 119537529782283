import { Component, AfterViewInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { ForTooltipComponent } from '../tooltip/for-tooltip.component';
import * as Highcharts from 'highcharts';
import { DataService } from '../../services/data.service';
import { AppService } from '../../services/app.service';
import { PaginatorService } from '../../services/paginator.service';
import { LoadingService } from '../../services/loading.service';
import { BehaviorSubject, } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-xmlImportTest',
  templateUrl: './xmlImportTest.component.html',
})
export class XMLImportTestComponent {

  @ViewChild('file', { static: true }) file: ElementRef;

  xmlValidationObject: any;
  showUploadButton = true;
  sendXMLValidationToEmail = false;
  sendXMLConfirmationToEmail = false;
  fileName = "";

  constructor(private router: Router, private dataService: DataService, private appService: AppService, private paginatorService: PaginatorService, private zone: NgZone) {

  }

  resetXMLValidationObject() {
    this.xmlValidationObject = {
      ListOfInspectionDataPatient: [],
      ListOfInspectionDataRun: [],
      ListOfNotEligiblePatients: [],
      PatientRunErrors: [],
      XMLFormatErrorsList: [],
      XMLNotFormatedErrors: [],
      XMLRequiredFieldsAndWrongValuesErrorsList: [],
      ExistingPatients: [],
      PatientsToUpdateOrAdd: []
    }

    this.showUploadButton = true;
  }
  uploadFile() {
    //console.log(this.file.nativeElement.files);
    if (this.file.nativeElement.files.length === 0) {
      return;
    }

    let fileToUpload = <File>this.file.nativeElement.files[0];
    const formData = new FormData();
    formData.append('uploadXMLFile', fileToUpload, fileToUpload.name);

    this.dataService.uploadXMLImport('XMLImport/UploadXMLFile', formData, this.fileName, this.sendXMLValidationToEmail, this.sendXMLConfirmationToEmail).subscribe(
      (res: any) => {
        //console.log(res);
       // this.resetXMLValidationObject();
        this.xmlValidationObject = res.data;
        console.log(this.xmlValidationObject);
      },
      err => {
        console.log(err);
      }
    )

    //reset input file value
    this.file.nativeElement.value = null;
  }

  updateAddPatientsAndRuns() {
    this.sendXMLValidationToEmail = null;
    this.sendXMLConfirmationToEmail = null;
    //Show message of import done, because we dont realy import data for XML import
    this.showUploadButton = false;
    this.fileName = "";
  }

  ngOnInit() {
    this.resetXMLValidationObject();
  }




}
