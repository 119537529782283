import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { error } from 'highcharts';
import { MinMaxValidationService } from '../../services/minmax-validation.service';

@Component({
  selector: 'elso-input',
  templateUrl: './elso-input.component.html',
  //styleUrls: ['./elso-datetimepicker.component.scss']
})

export class ELSOInputComponent {
  @Input() public model: FormControl;
  @Input() public id: string;
  @Input() public name: string;
  @Input() public form: FormGroup;
  @Input() public required: boolean;


  constructor(private minMaxValidationService: MinMaxValidationService) {
    //console.log('myCustomComponent');

  }

  public softMin: string;
  public softMax: string;
  public hardMin: string;
  public hardMax: string;
  AfterViewChecked() {
    console.log(this.minMaxValidationService.MinMaxHardSoftValues);
    this.minMaxValidationService.getMinMaxHardSoftValues().subscribe((result) => {
      var field = result;
      console.log('bingo', result);
      this.softMin = field.SoftMin;
      this.softMax = field.SoftMax;
      this.hardMin = field.SoftMin;
      this.hardMax = field.HardMax;
    }, (error) => {
      console.log('error', error);
    });

  

    console.log(this.model);
    console.log(this.id);
    console.log(this.name);
    console.log(this.form);
    console.log(this.required);
  }
}
