
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularMaterialModule } from './customModules/angular-material.module'
import { SharedModule } from './customModules/shared.module';

import { AppRoutingModule } from './routingModules/app-routing.module';


import { ToastrModule } from 'ngx-toastr';
///https://github.com/joejordanbrown/popover/blob/master/projects/material-extended/mde/src/lib/popover/popover.md
///https://github.com/joejordanbrown/popover
import { MdePopoverModule } from '@material-extended/mde';

//import { AuthGuard } from './guards/auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AppService } from './services/app.service';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoadingService } from './services/loading.service';


import { SettingsComponent } from './components/settings/settings.component';
import { InternationalReportComponent } from './components/reports/internationalReport/internationalreport.component';
import { ReportOverallOutcomeComponent } from './customComponents/reports/reportOverallOutcome/reportOverallOutcome.component';
import { ReportRunsByYear } from './customComponents/reports/reportRunsByYear/reportRunsByYear.component';
import { ReportDiagnoseFromYear } from './customComponents/reports/reportDiagnoseFromYear/reportDiagnoseFromYear.component';
import { ReportSupportModeFromYear } from './customComponents/reports/reportSupportModeFromYear/reportSupportModeFromYear.component';
import { ReportSupportModeFromYearConversion } from './customComponents/reports/reportSupportModeFromYearConversion/reportSupportModeFromYearConversion.component';
import { ReportComplicationFromYear } from './customComponents/reports/reportComplicationFromYear/reportComplicationFromYear.component';
import { CenterSpecificDevicesComponent } from './components/settings/centerSpecificDevices/centerSpecificDevices.component';
import { BroadcastService } from './services/broadcast.service';
import { EnumService } from './services/enum.service';
import { MatTabsModule } from '@angular/material/tabs';
import { DevicesFilterPipe } from './pipes/devices-filter.pipe';
import { AllRunsComponent } from './components/allRuns/allRuns.component';
import { RunPatientDeletionComponent } from './components/runPatientDeletion/runPatientDeletion.component';
import { ScanDeviceVideoComponent } from './customComponents/scanDeviceVideo/scanDeviceVideo.component';
import { DeviceScannerComponent } from './customComponents/deviceScanner/deviceScanner.component';
import { ScanDeviceImageComponent } from './customComponents/scanDeviceImage/scanDeviceImage.component';
import { ScanDeviceComponent, ScanDeviceDialog } from './components/scanDevice/scanDevice.component';
import { PatientsWithSandDevicesComponent, ScanDevicePatientDialog } from './components/patientsWithSandDevices/patientsWithSandDevices.component';
import { XMLImportComponent } from './components/xmlImport/xmlImport.component';
import { XMLImportTestComponent } from './components/xmlImport/xmlImportTest.component';
import { RunDataDumpComponent } from './components/runDataDump/runDataDump.component';
import { LoginSecretComponent } from './components/login/loginSecret.component';
import { TestDPComponent } from './components/testDP/testDP.component';
import { CustomPatientReportComponent } from './components/reports/customPatientReport/customPatientReport.component';
import { LoSReportComponent } from './components/reports/losReport/losReport.component';
/*import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';*/
//import { initializeKeycloak } from './utility/app.init';


//function initializeKeycloak(keycloak: KeycloakService) {
//  return () =>
//    keycloak.init({
//      config: {
//        url: 'http://localhost:8080/',
//        realm: 'angular-web',
//        clientId: 'AngularKeycloakSampleProjectID2'
//      }
//      , initOptions: {
//        onLoad: 'check-sso',
//        silentCheckSsoRedirectUri:
//          window.location.origin + '/assets/silent-check-sso.html'
//      }
//      , loadUserProfileAtStartUp: true
//    });
//}


@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    MdePopoverModule,
    MatTabsModule,
    //KeycloakAngularModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    LoginSecretComponent,
    RegisterComponent,
    TestDPComponent,

    HomeComponent,
    AllRunsComponent,
    RunPatientDeletionComponent,
    SettingsComponent,
    RunDataDumpComponent,
    CenterSpecificDevicesComponent,

    DeviceScannerComponent,
    ScanDeviceImageComponent,
    ScanDeviceVideoComponent,
    ScanDeviceComponent,
    ScanDeviceDialog,
    ScanDevicePatientDialog,
    PatientsWithSandDevicesComponent,

    XMLImportComponent,
    XMLImportTestComponent,

    LoSReportComponent,
    InternationalReportComponent,
    ReportOverallOutcomeComponent,
    ReportRunsByYear,
    ReportDiagnoseFromYear,
    ReportSupportModeFromYear,
    ReportSupportModeFromYearConversion,
    ReportComplicationFromYear,
    CustomPatientReportComponent,

    DevicesFilterPipe

  ],
  entryComponents: [
    //////Its required set in entryComponents: material modal and component that will be dinamycally loaded inside modal for dynamically loaded component to work fine and load its sub components.
    /////Without this Angular will reload vies when some button is clicked inside dynamically loaded component.
    ScanDeviceDialog,
    ScanDevicePatientDialog,
    DeviceScannerComponent,
    //////////////////////
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    //{
    //  provide: APP_INITIALIZER,
    //  useFactory: initializeKeycloak,
    //  multi: true,
    //  deps: [KeycloakService]
    //},
    AppService,
    LoadingService,
    BroadcastService,
    EnumService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
